var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: true,
      expression: "true"
    }]
  }, [_c('b-row', [_c('b-col', [_c('filtering-panel', {
    ref: "filteringPanel",
    attrs: {
      "mode": "server",
      "dataset-name": "product-reports/warehouse-sales",
      "show-labels": false,
      "compact": true,
      "districts": _vm.filteringPanel.districts,
      "loaded": _vm.filteringPanel.loaded,
      "filters": _vm.filteringPanel.filters
    },
    on: {
      "change": _vm.onFilteringPanelChange,
      "search": function search($event) {
        return _vm.getData(0);
      },
      "reset": _vm.onFilteringPanelReset
    }
  })], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', {
    staticClass: "d-flex align-items-end",
    attrs: {
      "lg": "6"
    }
  }, [_c('b-popover', {
    attrs: {
      "target": "chart1-help",
      "triggers": "hover",
      "placement": "right"
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.popoverTemplate)
    }
  })]), _c('g-chart-custom', {
    ref: "chart1",
    attrs: {
      "default-mode": _vm.charts.chart1.isDefaultMode,
      "title": "Top selling ".concat(this.selectedMode().label),
      "type": _vm.charts.chart1.type,
      "loading": _vm.charts.chart1.loading,
      "data": _vm.charts.chart1.chartData,
      "options": _vm.charts.chart1.chartOptions
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('i', {
          staticClass: "fa fa-question-circle",
          attrs: {
            "id": "chart1-help"
          }
        })];
      },
      proxy: true
    }, {
      key: "filter",
      fn: function fn() {
        return [_vm._v(" Compare to: "), _c('filtering-panel', {
          ref: "filteringPanel1",
          attrs: {
            "mode": "server",
            "dataset-name": "product-reports/accounts-sales-chart-1",
            "show-labels": false,
            "compact": true,
            "hide-reset-button": true,
            "loaded": _vm.filteringPanel1.loaded,
            "filters": _vm.filteringPanel1.filters
          },
          on: {
            "search": function search($event) {
              return _vm.getData(1);
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "chartTopBar",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col', [_c('b-pagination', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart1.isDefaultMode,
            expression: "charts.chart1.isDefaultMode"
          }],
          attrs: {
            "pills": "",
            "hide-ellipsis": true,
            "total-rows": _vm.charts.chart1.pagination.total,
            "per-page": 10
          },
          on: {
            "input": _vm.updateChart1
          },
          model: {
            value: _vm.charts.chart1.pagination.value,
            callback: function callback($$v) {
              _vm.$set(_vm.charts.chart1.pagination, "value", $$v);
            },
            expression: "charts.chart1.pagination.value"
          }
        }), _c('p', [_vm._v(_vm._s(_vm.charts.chart1.subtitle))])], 1), _c('b-col', {
          staticClass: "text-right"
        }, [_c('b-button-group', [_c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart1.isColumnType = false;
              _vm.charts.chart1.type = 'PieChart';
              _vm.charts.chart1.isDefaultMode = true;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-pie"
          }
        })], 1), _c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart1.isColumnType = true;
              _vm.charts.chart1.type = 'BarChart';
              _vm.charts.chart1.isDefaultMode = true;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-bar"
          }
        })], 1), _c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart1.isDefaultMode = false;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "list"
          }
        })], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "table",
      fn: function fn() {
        return [_c('table-custom', {
          attrs: {
            "name": _vm.chart1Table.name,
            "loading": _vm.chart1Table.isLoading,
            "data": _vm.chart1Table.dataSet,
            "options": _vm.chart1Table.options
          },
          on: {
            "mounted": _vm.onChart1TableMount
          }
        })];
      },
      proxy: true
    }, {
      key: "chartBottomBar",
      fn: function fn() {
        return [_c('b-row', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart1.isDefaultMode,
            expression: "charts.chart1.isDefaultMode"
          }],
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col', {
          staticClass: "text-left"
        }, [_c('strong', [_vm._v("Total sold:")]), _vm._v(" $" + _vm._s(_vm.charts.chart1.totals.totalSold.toLocaleString("en-US", "currency")) + " "), _c('br'), _c('strong', [_vm._v("Total sold selection:")]), _vm._v(" $" + _vm._s(_vm.charts.chart1.totals.totalSoldSelection.toLocaleString("en-US", "currency")) + " ")])], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    staticClass: "d-flex align-items-end",
    attrs: {
      "lg": "6"
    }
  }, [_c('g-chart-custom', {
    ref: "chart2",
    attrs: {
      "default-mode": _vm.charts.chart2.isDefaultMode,
      "title": "",
      "type": _vm.charts.chart2.type,
      "loading": _vm.charts.chart2.loading,
      "data": _vm.charts.chart2.chartData,
      "options": _vm.charts.chart2.chartOptions
    },
    scopedSlots: _vm._u([{
      key: "chartTopBar",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col', [_c('b-pagination', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart2.isDefaultMode,
            expression: "charts.chart2.isDefaultMode"
          }],
          attrs: {
            "pills": "",
            "hide-ellipsis": true,
            "total-rows": _vm.charts.chart2.pagination.total,
            "per-page": 10
          },
          on: {
            "input": _vm.updateChart2
          },
          model: {
            value: _vm.charts.chart2.pagination.value,
            callback: function callback($$v) {
              _vm.$set(_vm.charts.chart2.pagination, "value", $$v);
            },
            expression: "charts.chart2.pagination.value"
          }
        }), _c('p', [_vm._v(_vm._s(_vm.charts.chart2.subtitle))])], 1), _c('b-col', {
          staticClass: "text-right"
        }, [_c('b-button-group', [_c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart2.isColumnType = false;
              _vm.charts.chart2.type = 'PieChart';
              _vm.charts.chart2.isDefaultMode = true;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-pie"
          }
        })], 1), _c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart2.isColumnType = true;
              _vm.charts.chart2.type = 'BarChart';
              _vm.charts.chart2.isDefaultMode = true;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-bar"
          }
        })], 1), _c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart2.isDefaultMode = false;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "list"
          }
        })], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "table",
      fn: function fn() {
        return [_c('table-custom', {
          attrs: {
            "name": _vm.chart2Table.name,
            "loading": _vm.chart2Table.isLoading,
            "data": _vm.chart2Table.dataSet,
            "options": _vm.chart2Table.options
          },
          on: {
            "mounted": _vm.onChart2TableMount
          }
        })];
      },
      proxy: true
    }, {
      key: "chartBottomBar",
      fn: function fn() {
        return [_c('b-row', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart2.isDefaultMode,
            expression: "charts.chart2.isDefaultMode"
          }],
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col', {
          staticClass: "text-left"
        }, [_c('strong', [_vm._v("Total sold:")]), _vm._v(" $" + _vm._s(_vm.charts.chart2.totals.totalSold.toLocaleString("en-US", "currency")) + " "), _c('br'), _c('strong', [_vm._v("Total sold selection:")]), _vm._v(" $" + _vm._s(_vm.charts.chart2.totals.totalSoldSelection.toLocaleString("en-US", "currency")) + " ")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-popover', {
    attrs: {
      "target": "chart3-help",
      "triggers": "hover",
      "placement": "right"
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.popoverTemplate)
    }
  })]), _c('g-chart-custom', {
    ref: "chart3",
    attrs: {
      "default-mode": _vm.charts.chart3.isDefaultMode,
      "title": "Top selling ".concat(this.selectedMode().label, " growth (").concat(this.selectedMetric().label, ")"),
      "subtitle": _vm.charts.chart3.subtitle,
      "type": "BarChart",
      "loading": _vm.charts.chart3.loading,
      "data": _vm.charts.chart3.chartData,
      "options": _vm.charts.chart3.chartOptions
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('i', {
          staticClass: "fa fa-question-circle",
          attrs: {
            "id": "chart3-help"
          }
        })];
      },
      proxy: true
    }, {
      key: "filter",
      fn: function fn() {
        return [_vm._v(" Compare to: "), _c('filtering-panel', {
          ref: "filteringPanel2",
          attrs: {
            "mode": "server",
            "dataset-name": "product-reports/accounts-sales-chart-3",
            "show-labels": false,
            "compact": true,
            "hide-reset-button": true,
            "loaded": _vm.filteringPanel2.loaded,
            "filters": _vm.filteringPanel2.filters
          },
          on: {
            "search": function search($event) {
              return _vm.getData(2);
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "chartTopBar",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col', [_c('b-pagination', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart3.isDefaultMode,
            expression: "charts.chart3.isDefaultMode"
          }],
          attrs: {
            "pills": "",
            "hide-ellipsis": true,
            "total-rows": _vm.charts.chart3.pagination.total,
            "per-page": 10
          },
          on: {
            "input": _vm.updateChart3
          },
          model: {
            value: _vm.charts.chart3.pagination.value,
            callback: function callback($$v) {
              _vm.$set(_vm.charts.chart3.pagination, "value", $$v);
            },
            expression: "charts.chart3.pagination.value"
          }
        })], 1), _c('b-col', {
          staticClass: "text-right"
        }, [_c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart3.isDefaultMode,
            expression: "charts.chart3.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart3.isDefaultMode = !_vm.charts.chart3.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "list"
          }
        })], 1), _c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.charts.chart3.isDefaultMode,
            expression: "!charts.chart3.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart3.isDefaultMode = !_vm.charts.chart3.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-bar"
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "table",
      fn: function fn() {
        return [_c('table-custom', {
          attrs: {
            "name": _vm.chart3Table.name,
            "loading": _vm.chart3Table.isLoading,
            "data": _vm.chart3Table.dataSet,
            "options": _vm.chart3Table.options
          },
          on: {
            "mounted": _vm.onChart3TableMount
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', {
    staticClass: "d-flex align-items-end",
    attrs: {
      "lg": "6"
    }
  }, [_c('g-chart-custom', {
    ref: "chart4",
    attrs: {
      "default-mode": _vm.charts.chart4.isDefaultMode,
      "title": "Invoices & Accounts",
      "type": "BarChart",
      "loading": _vm.charts.chart4.loading,
      "data": _vm.charts.chart4.chartData,
      "options": _vm.charts.chart4.chartOptions
    },
    on: {
      "chart-element-selected": _vm.drilldownChart41
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('b-popover', {
          attrs: {
            "target": "chart4-help",
            "triggers": "hover",
            "placement": "right"
          }
        }, [_c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.popoverTemplate)
          }
        })]), _c('i', {
          staticClass: "fa fa-question-circle",
          attrs: {
            "id": "chart4-help"
          }
        }), _c('b-popover', {
          attrs: {
            "target": "chart4-ddi",
            "triggers": "hover",
            "placement": "right"
          }
        }, [_c('span', [_vm._v("Chart drilldown available")])]), _c('i', {
          staticClass: "ml-1 fa fa-share fa-rotate-90",
          attrs: {
            "id": "chart4-ddi"
          }
        })];
      },
      proxy: true
    }, {
      key: "filter",
      fn: function fn() {
        return [_vm._v(" Compare to: "), _c('filtering-panel', {
          ref: "filteringPanel3",
          attrs: {
            "mode": "server",
            "dataset-name": "product-reports/accounts-sales-chart-4",
            "show-labels": false,
            "compact": true,
            "hide-reset-button": true,
            "loaded": _vm.filteringPanel3.loaded,
            "filters": _vm.filteringPanel3.filters
          },
          on: {
            "search": function search($event) {
              return _vm.getData(3);
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "chartTopBar",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col', [_c('b-pagination', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart4.isDefaultMode,
            expression: "charts.chart4.isDefaultMode"
          }],
          attrs: {
            "pills": "",
            "hide-ellipsis": true,
            "total-rows": _vm.charts.chart4.pagination.total,
            "per-page": 10
          },
          on: {
            "input": _vm.updateChart4
          },
          model: {
            value: _vm.charts.chart4.pagination.value,
            callback: function callback($$v) {
              _vm.$set(_vm.charts.chart4.pagination, "value", $$v);
            },
            expression: "charts.chart4.pagination.value"
          }
        }), _c('p', [_vm._v(_vm._s(_vm.charts.chart4.subtitle))])], 1), _c('b-col', {
          staticClass: "text-right"
        }, [_c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart4.isDefaultMode,
            expression: "charts.chart4.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart4.isDefaultMode = !_vm.charts.chart4.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "list"
          }
        })], 1), _c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.charts.chart4.isDefaultMode,
            expression: "!charts.chart4.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart4.isDefaultMode = !_vm.charts.chart4.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-bar"
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "table",
      fn: function fn() {
        return [_c('table-custom', {
          attrs: {
            "name": _vm.chart4Table.name,
            "loading": _vm.chart4Table.isLoading,
            "data": _vm.chart4Table.dataSet,
            "options": _vm.chart4Table.options
          },
          on: {
            "mounted": _vm.onChart4TableMount
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    staticClass: "d-flex align-items-end",
    attrs: {
      "lg": "6"
    }
  }, [_c('g-chart-custom', {
    ref: "chart5",
    attrs: {
      "default-mode": _vm.charts.chart5.isDefaultMode,
      "type": "BarChart",
      "loading": _vm.charts.chart5.loading,
      "data": _vm.charts.chart5.chartData,
      "options": _vm.charts.chart5.chartOptions
    },
    on: {
      "chart-element-selected": _vm.drilldownChart42
    },
    scopedSlots: _vm._u([{
      key: "chartTopBar",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col', [_c('b-pagination', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart5.isDefaultMode,
            expression: "charts.chart5.isDefaultMode"
          }],
          attrs: {
            "pills": "",
            "hide-ellipsis": true,
            "total-rows": _vm.charts.chart5.pagination.total,
            "per-page": 10
          },
          on: {
            "input": _vm.updateChart5
          },
          model: {
            value: _vm.charts.chart5.pagination.value,
            callback: function callback($$v) {
              _vm.$set(_vm.charts.chart5.pagination, "value", $$v);
            },
            expression: "charts.chart5.pagination.value"
          }
        }), _c('p', [_vm._v(_vm._s(_vm.charts.chart5.subtitle))])], 1), _c('b-col', {
          staticClass: "text-right"
        }, [_c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart5.isDefaultMode,
            expression: "charts.chart5.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart5.isDefaultMode = !_vm.charts.chart5.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "list"
          }
        })], 1), _c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.charts.chart5.isDefaultMode,
            expression: "!charts.chart5.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart5.isDefaultMode = !_vm.charts.chart5.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-bar"
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "table",
      fn: function fn() {
        return [_c('table-custom', {
          attrs: {
            "name": _vm.chart5Table.name,
            "loading": _vm.chart5Table.isLoading,
            "data": _vm.chart5Table.dataSet,
            "options": _vm.chart5Table.options
          },
          on: {
            "mounted": _vm.onChart5TableMount
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }