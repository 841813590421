<template>
  <div class="animated fadeIn">
    <b-card v-show="true">
      <b-row>
        <b-col>
          <filtering-panel
            ref="filteringPanel"
            mode="server"
            dataset-name="product-reports/warehouse-sales"
            :show-labels="false"
            :compact="true"
            :districts="filteringPanel.districts"
            :loaded="filteringPanel.loaded"
            :filters="filteringPanel.filters"
            @change="onFilteringPanelChange"
            @search="getData(0)"
            @reset="onFilteringPanelReset"
          />
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col lg="6" class="d-flex align-items-end">
          <b-popover target="chart1-help" triggers="hover" placement="right">
            <span v-html="popoverTemplate" />
          </b-popover>
          <g-chart-custom
            ref="chart1"
            :default-mode="charts.chart1.isDefaultMode"
            :title="`Top selling ${this.selectedMode().label}`"
            :type="charts.chart1.type"
            :loading="charts.chart1.loading"
            :data="charts.chart1.chartData"
            :options="charts.chart1.chartOptions"
          >
            <template #legend>
              <i id="chart1-help" class="fa fa-question-circle" />
            </template>
            <template #filter>
              Compare to:
              <filtering-panel
                ref="filteringPanel1"
                mode="server"
                dataset-name="product-reports/accounts-sales-chart-1"
                :show-labels="false"
                :compact="true"
                :hide-reset-button="true"
                :loaded="filteringPanel1.loaded"
                :filters="filteringPanel1.filters"
                @search="getData(1)"
              />
            </template>
            <template #chartTopBar>
              <b-row align-h="between">
                <b-col>
                  <b-pagination
                    v-show="charts.chart1.isDefaultMode"
                    v-model="charts.chart1.pagination.value"
                    pills
                    :hide-ellipsis="true"
                    :total-rows="charts.chart1.pagination.total"
                    :per-page="10"
                    @input="updateChart1"
                  />
                  <p>{{ charts.chart1.subtitle }}</p>
                </b-col>
                <b-col class="text-right">
                  <b-button-group>
                    <b-button
                      class="mb-2"
                      variant="outline-primary"
                      @click="
                        charts.chart1.isColumnType = false;
                        charts.chart1.type = 'PieChart';
                        charts.chart1.isDefaultMode = true;
                      "
                    >
                      <font-awesome-icon icon="chart-pie" />
                    </b-button>
                    <b-button
                      class="mb-2"
                      variant="outline-primary"
                      @click="
                        charts.chart1.isColumnType = true;
                        charts.chart1.type = 'BarChart';
                        charts.chart1.isDefaultMode = true;
                      "
                    >
                      <font-awesome-icon icon="chart-bar" />
                    </b-button>
                    <b-button
                      class="mb-2"
                      variant="outline-primary"
                      @click="charts.chart1.isDefaultMode = false"
                    >
                      <font-awesome-icon icon="list" />
                    </b-button>
                  </b-button-group>
                </b-col>
              </b-row>
            </template>
            <template #table>
              <table-custom
                :name="chart1Table.name"
                :loading="chart1Table.isLoading"
                :data="chart1Table.dataSet"
                :options="chart1Table.options"
                @mounted="onChart1TableMount"
              />
            </template>
            <template #chartBottomBar>
              <b-row align-h="between" v-show="charts.chart1.isDefaultMode">
                <b-col class="text-left">
                  <strong>Total sold:</strong> ${{
                    charts.chart1.totals.totalSold.toLocaleString(
                      "en-US",
                      "currency"
                    )
                  }}
                  <br />
                  <strong>Total sold selection:</strong> ${{
                    charts.chart1.totals.totalSoldSelection.toLocaleString(
                      "en-US",
                      "currency"
                    )
                  }}
                </b-col>
              </b-row>
            </template>
          </g-chart-custom>
        </b-col>
        <b-col lg="6" class="d-flex align-items-end">
          <g-chart-custom
            ref="chart2"
            :default-mode="charts.chart2.isDefaultMode"
            title=""
            :type="charts.chart2.type"
            :loading="charts.chart2.loading"
            :data="charts.chart2.chartData"
            :options="charts.chart2.chartOptions"
          >
            <template #chartTopBar>
              <b-row align-h="between">
                <b-col>
                  <b-pagination
                    v-show="charts.chart2.isDefaultMode"
                    v-model="charts.chart2.pagination.value"
                    pills
                    :hide-ellipsis="true"
                    :total-rows="charts.chart2.pagination.total"
                    :per-page="10"
                    @input="updateChart2"
                  />
                  <p>{{ charts.chart2.subtitle }}</p>
                </b-col>
                <b-col class="text-right">
                  <b-button-group>
                    <b-button
                      class="mb-2"
                      variant="outline-primary"
                      @click="
                        charts.chart2.isColumnType = false;
                        charts.chart2.type = 'PieChart';
                        charts.chart2.isDefaultMode = true;
                      "
                    >
                      <font-awesome-icon icon="chart-pie" />
                    </b-button>
                    <b-button
                      class="mb-2"
                      variant="outline-primary"
                      @click="
                        charts.chart2.isColumnType = true;
                        charts.chart2.type = 'BarChart';
                        charts.chart2.isDefaultMode = true;
                      "
                    >
                      <font-awesome-icon icon="chart-bar" />
                    </b-button>
                    <b-button
                      class="mb-2"
                      variant="outline-primary"
                      @click="charts.chart2.isDefaultMode = false"
                    >
                      <font-awesome-icon icon="list" />
                    </b-button>
                  </b-button-group>
                </b-col>
              </b-row>
            </template>
            <template #table>
              <table-custom
                :name="chart2Table.name"
                :loading="chart2Table.isLoading"
                :data="chart2Table.dataSet"
                :options="chart2Table.options"
                @mounted="onChart2TableMount"
              />
            </template>
            <template #chartBottomBar>
              <b-row align-h="between" v-show="charts.chart2.isDefaultMode">
                <b-col class="text-left">
                  <strong>Total sold:</strong> ${{
                    charts.chart2.totals.totalSold.toLocaleString(
                      "en-US",
                      "currency"
                    )
                  }}
                  <br />
                  <strong>Total sold selection:</strong> ${{
                    charts.chart2.totals.totalSoldSelection.toLocaleString(
                      "en-US",
                      "currency"
                    )
                  }}
                </b-col>
              </b-row>
            </template>
          </g-chart-custom>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col lg="12">
          <b-popover target="chart3-help" triggers="hover" placement="right">
            <span v-html="popoverTemplate" />
          </b-popover>
          <g-chart-custom
            ref="chart3"
            :default-mode="charts.chart3.isDefaultMode"
            :title="
              `Top selling ${this.selectedMode().label} growth (${
                this.selectedMetric().label
              })`
            "
            :subtitle="charts.chart3.subtitle"
            type="BarChart"
            :loading="charts.chart3.loading"
            :data="charts.chart3.chartData"
            :options="charts.chart3.chartOptions"
          >
            <template #legend>
              <i id="chart3-help" class="fa fa-question-circle" />
            </template>
            <template #filter>
              Compare to:
              <filtering-panel
                ref="filteringPanel2"
                mode="server"
                dataset-name="product-reports/accounts-sales-chart-3"
                :show-labels="false"
                :compact="true"
                :hide-reset-button="true"
                :loaded="filteringPanel2.loaded"
                :filters="filteringPanel2.filters"
                @search="getData(2)"
              />
            </template>
            <template #chartTopBar>
              <b-row align-h="between">
                <b-col>
                  <b-pagination
                    v-show="charts.chart3.isDefaultMode"
                    v-model="charts.chart3.pagination.value"
                    pills
                    :hide-ellipsis="true"
                    :total-rows="charts.chart3.pagination.total"
                    :per-page="10"
                    @input="updateChart3"
                  />
                </b-col>
                <b-col class="text-right">
                  <b-button
                    v-show="charts.chart3.isDefaultMode"
                    class="mb-2"
                    variant="outline-primary"
                    @click="
                      charts.chart3.isDefaultMode = !charts.chart3.isDefaultMode
                    "
                  >
                    <font-awesome-icon icon="list" />
                  </b-button>
                  <b-button
                    v-show="!charts.chart3.isDefaultMode"
                    class="mb-2"
                    variant="outline-primary"
                    @click="
                      charts.chart3.isDefaultMode = !charts.chart3.isDefaultMode
                    "
                  >
                    <font-awesome-icon icon="chart-bar" />
                  </b-button>
                </b-col>
              </b-row>
            </template>
            <template #table>
              <table-custom
                :name="chart3Table.name"
                :loading="chart3Table.isLoading"
                :data="chart3Table.dataSet"
                :options="chart3Table.options"
                @mounted="onChart3TableMount"
              />
            </template>
          </g-chart-custom>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col lg="6" class="d-flex align-items-end">
          <g-chart-custom
            ref="chart4"
            :default-mode="charts.chart4.isDefaultMode"
            title="Invoices & Accounts"
            type="BarChart"
            :loading="charts.chart4.loading"
            :data="charts.chart4.chartData"
            :options="charts.chart4.chartOptions"
            @chart-element-selected="drilldownChart41"
          >
            <template #legend>
              <b-popover
                target="chart4-help"
                triggers="hover"
                placement="right"
              >
                <span v-html="popoverTemplate" />
              </b-popover>
              <i id="chart4-help" class="fa fa-question-circle" />

              <b-popover target="chart4-ddi" triggers="hover" placement="right">
                <span>Chart drilldown available</span>
              </b-popover>
              <i id="chart4-ddi" class="ml-1 fa fa-share fa-rotate-90" />
            </template>

            <template #filter>
              Compare to:
              <filtering-panel
                ref="filteringPanel3"
                mode="server"
                dataset-name="product-reports/accounts-sales-chart-4"
                :show-labels="false"
                :compact="true"
                :hide-reset-button="true"
                :loaded="filteringPanel3.loaded"
                :filters="filteringPanel3.filters"
                @search="getData(3)"
              />
            </template>

            <template #chartTopBar>
              <b-row align-h="between">
                <b-col>
                  <b-pagination
                    v-show="charts.chart4.isDefaultMode"
                    v-model="charts.chart4.pagination.value"
                    pills
                    :hide-ellipsis="true"
                    :total-rows="charts.chart4.pagination.total"
                    :per-page="10"
                    @input="updateChart4"
                  />
                  <p>{{ charts.chart4.subtitle }}</p>
                </b-col>
                <b-col class="text-right">
                  <b-button
                    v-show="charts.chart4.isDefaultMode"
                    class="mb-2"
                    variant="outline-primary"
                    @click="
                      charts.chart4.isDefaultMode = !charts.chart4.isDefaultMode
                    "
                  >
                    <font-awesome-icon icon="list" />
                  </b-button>
                  <b-button
                    v-show="!charts.chart4.isDefaultMode"
                    class="mb-2"
                    variant="outline-primary"
                    @click="
                      charts.chart4.isDefaultMode = !charts.chart4.isDefaultMode
                    "
                  >
                    <font-awesome-icon icon="chart-bar" />
                  </b-button>
                </b-col>
              </b-row>
            </template>
            <template #table>
              <table-custom
                :name="chart4Table.name"
                :loading="chart4Table.isLoading"
                :data="chart4Table.dataSet"
                :options="chart4Table.options"
                @mounted="onChart4TableMount"
              />
            </template>
          </g-chart-custom>
        </b-col>
        <b-col lg="6" class="d-flex align-items-end">
          <g-chart-custom
            ref="chart5"
            :default-mode="charts.chart5.isDefaultMode"
            type="BarChart"
            :loading="charts.chart5.loading"
            :data="charts.chart5.chartData"
            :options="charts.chart5.chartOptions"
            @chart-element-selected="drilldownChart42"
          >
            <template #chartTopBar>
              <b-row align-h="between">
                <b-col>
                  <b-pagination
                    v-show="charts.chart5.isDefaultMode"
                    v-model="charts.chart5.pagination.value"
                    pills
                    :hide-ellipsis="true"
                    :total-rows="charts.chart5.pagination.total"
                    :per-page="10"
                    @input="updateChart5"
                  />
                  <p>{{ charts.chart5.subtitle }}</p>
                </b-col>
                <b-col class="text-right">
                  <b-button
                    v-show="charts.chart5.isDefaultMode"
                    class="mb-2"
                    variant="outline-primary"
                    @click="
                      charts.chart5.isDefaultMode = !charts.chart5.isDefaultMode
                    "
                  >
                    <font-awesome-icon icon="list" />
                  </b-button>
                  <b-button
                    v-show="!charts.chart5.isDefaultMode"
                    class="mb-2"
                    variant="outline-primary"
                    @click="
                      charts.chart5.isDefaultMode = !charts.chart5.isDefaultMode
                    "
                  >
                    <font-awesome-icon icon="chart-bar" />
                  </b-button>
                </b-col>
              </b-row>
            </template>
            <template #table>
              <table-custom
                :name="chart5Table.name"
                :loading="chart5Table.isLoading"
                :data="chart5Table.dataSet"
                :options="chart5Table.options"
                @mounted="onChart5TableMount"
              />
            </template>
          </g-chart-custom>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import moment from "moment";

import FilteringPanel from "@/components/FilteringPanel";
import GChartCustom from "@/components/GChartCustom";
import TableCustom from "@/components/TableCustom";

import fp from "./filters";
import charts from "./charts";
import tables from "./tables";

export default {
  name: "Overview",
  components: {
    FilteringPanel,
    GChartCustom,
    TableCustom
  },
  data: function() {
    return {
      isLoading: false,
      activeTab: 0,
      filteringPanel: fp.filteringPanel,
      filteringPanel1: fp.filteringPanel1,
      filteringPanel2: fp.filteringPanel2,
      filteringPanel3: fp.filteringPanel3,
      popoverTemplate:
        "<ul> <li>Selection period: The user selected date range</li> </ul>",
      popoverTemplateAllMetrics:
        "<ul> <li>Selection period: The user selected date range</li><li>Reference period: A time period of equal length immediately prior to the selected date range</li> <li>Same period one year prior: The selected date range one year prior to user input</li> </ul>",
      charts: charts,
      chart1Table: tables.chart1Table,
      chart2Table: tables.chart2Table,
      chart3Table: tables.chart3Table,
      chart4Table: tables.chart4Table,
      chart5Table: tables.chart5Table,
      districts: []
    };
  },
  computed: {},
  async mounted() {
    if (!this.$refs.filteringPanel.selected.metric)
      this.$refs.filteringPanel.selected.metric = this.filteringPanel.filters.find(
        f => f.name === "metric"
      ).options[0];

    await this.getDictionaries();

    this.updateDateRanges();

    this.getData(0);
  },
  methods: {
    selectedMode() {
      if (
        !this.$refs.filteringPanel ||
        !this.$refs.filteringPanel.selected.mode
      )
        return this.filteringPanel.filters.find(f => f.name === "mode")
          .options[0];

      return this.$refs.filteringPanel.selected.mode;
    },

    selectedMetric() {
      if (
        !this.$refs.filteringPanel ||
        !this.$refs.filteringPanel.selected.metric
      )
        return this.filteringPanel.filters.find(f => f.name === "metric")
          .options[0];

      return this.$refs.filteringPanel.selected.metric;
    },

    onFilteringPanelReset() {
      this.$refs.filteringPanel.resetFilters({
        resetStorage: true
      });

      this.$refs.filteringPanel1.resetFilters({
        resetStorage: true
      });

      this.$refs.filteringPanel2.resetFilters({
        resetStorage: true
      });

      this.$refs.filteringPanel3.resetFilters({
        resetStorage: true
      });

      this.updateDateRanges();
    },
    onFilteringPanelChange(payload) {
      this.updateDateRanges(payload);
    },
    updateDateRanges() {
      let filter = this.$refs.filteringPanel.selected;

      //if user values are not defined then set date range to 1 year prior period selected at the top
      //https://gitlab.com/koval-projects/koval-crm/-/issues/211#note_356827025

      let defaultPeriod = {
        startDate: moment(filter.period.startDate)
          .subtract(1, "year")
          .format("YYYY-MM-DD"),
        endDate: moment(filter.period.endDate)
          .subtract(1, "year")
          .format("YYYY-MM-DD")
      };
      //set default period if storage was empty

      this.$refs.filteringPanel1.filtersSaved2Storage ||
        this.$refs.filteringPanel1.$refs["period"][0].setValue(defaultPeriod, {
          triggerChanged: false
        });

      this.$refs.filteringPanel2.filtersSaved2Storage ||
        this.$refs.filteringPanel2.$refs["period"][0].setValue(defaultPeriod, {
          triggerChanged: false
        });

      this.$refs.filteringPanel3.filtersSaved2Storage ||
        this.$refs.filteringPanel3.$refs["period"][0].setValue(defaultPeriod, {
          triggerChanged: false
        });
    },

    async getDictionaries() {
      let self = this;

      let response = await self.$api.get("dictionaries/territories");

      let territories = response.map(u => ({ id: u.ID, label: u.Name }));

      response = await self.$api.get("dictionaries/accounts/distributors");

      let distributors = response.map(u => ({ id: u.id, label: u.name }));

      response = await self.$api.get("dictionaries/markets");

      let markets = response.map(u => ({ id: u.id, label: u.name }));

      response = await self.$api.get("dictionaries/products/sizes");

      let bottle_sizes = response.map(u => ({ id: u.id, label: u.name }));

      self.filteringPanel.filters.find(
        f => f.name === "territory"
      ).options = territories;
      self.filteringPanel1.filters.find(
        f => f.name === "territory"
      ).options = territories;
      self.filteringPanel2.filters.find(
        f => f.name === "territory"
      ).options = territories;
      self.filteringPanel3.filters.find(
        f => f.name === "territory"
      ).options = territories;

      self.filteringPanel.filters.find(
        f => f.name === "distributor"
      ).options = distributors;
      //self.filteringPanel1.filters.find(f => f.name === "distributor").options = distributors
      //self.filteringPanel2.filters.find(f => f.name === "distributor").options = distributors
      //self.filteringPanel3.filters.find(f => f.name === "distributor").options = distributors

      self.filteringPanel.filters.find(
        f => f.name === "market"
      ).options = markets;
      self.filteringPanel1.filters.find(
        f => f.name === "market"
      ).options = markets;
      self.filteringPanel2.filters.find(
        f => f.name === "market"
      ).options = markets;
      self.filteringPanel3.filters.find(
        f => f.name === "market"
      ).options = markets;

      self.filteringPanel.filters.find(
        f => f.name === "bottle_size"
      ).options = bottle_sizes;
    },
    async getData(_mode) {
      let self = this;

      let topFilter = this.$refs.filteringPanel.selected;

      let filter1 = this.$refs.filteringPanel1.selected;
      let filter2 = this.$refs.filteringPanel2.selected;
      let filter3 = this.$refs.filteringPanel3.selected;

      let filterRequest = Object.assign({}, topFilter);

      if (_mode === 0 || _mode === 1) {
        filterRequest.period2 = filter1.period;

        //overwrite top level filter if exists
        !filter1.territory ||
          (filterRequest.territory = Object.assign({}, filter1.territory));
        !filter1.market ||
          (filterRequest.market = Object.assign({}, filter1.market));
        !filter1.distributor ||
          (filterRequest.distributor = Object.assign({}, filter1.distributor));

        this.charts.chart1.loading = true;
        this.charts.chart2.loading = true;

        //this.charts.chart1.subtitle = `Percent change in ${this.selectedMetric().label} compared to ${moment(filter1.period.startDate).format('YYYY-MM-DD')} - ${moment(filter1.period.endDate).format('YYYY-MM-DD')}`

        this.$api.data
          .requestReportData("products/wh-overview/sales", filterRequest)
          .then(response => {
            self.drawChart1(response, filterRequest);
            self.drawChart2(response, filterRequest);
          });
      }

      if (_mode === 0 || _mode === 2) {
        filterRequest.period2 = filter2.period;

        //overwrite top level filter if exists
        !filter2.territory ||
          (filterRequest.territory = Object.assign({}, filter2.territory));
        !filter2.market ||
          (filterRequest.market = Object.assign({}, filter2.market));
        !filter2.distributor ||
          (filterRequest.distributor = Object.assign({}, filter2.distributor));

        this.charts.chart3.loading = true;

        //this.charts.chart1.subtitle = `Percent change in ${this.selectedMetric().label} compared to ${moment(filter1.period.startDate).format('YYYY-MM-DD')} - ${moment(filter1.period.endDate).format('YYYY-MM-DD')}`

        this.$api.data
          .requestReportData("products/wh-overview/sales", filterRequest)
          .then(response => {
            self.drawChart3(response, filterRequest);
          });
      }

      if (_mode === 0 || _mode === 3) {
        filterRequest.period2 = filter3.period;

        //overwrite top level filter if exists
        !filter3.territory ||
          (filterRequest.territory = Object.assign({}, filter3.territory));
        !filter3.market ||
          (filterRequest.market = Object.assign({}, filter3.market));
        !filter3.distributor ||
          (filterRequest.distributor = Object.assign({}, filter3.distributor));

        this.charts.chart4.loading = true;
        this.charts.chart5.loading = true;

        this.$api.data
          .requestReportData("products/wh-overview/invoices", filterRequest)
          .then(response => {
            self.drawChart4(response, filterRequest);
            self.drawChart5(response, filterRequest);
          });
      }
    },
    drawChart1: function(response) {
      this.charts.chart1.loading = false;

      if (response.length === 0) return;

      this.charts.chart1.rawData = response;

      this.updateChart1();
    },
    updateChart1() {
      let headers = [
        [
          "Product",
          "$ Sold",
          {
            type: "string",
            role: "tooltip"
          }
        ]
      ];

      let metric = undefined;

      if (this.charts.chart1.rawData.length === 0) return;

      metric = "sales1";

      let data = [
        ...this.charts.chart1.rawData.map(item => {
          const tooltip = `Product: ${
            item.productname
          }\nTotal sales $: ${parseFloat(item["sales1"]).toFixed(
            2
          )}\nBottles: ${parseFloat(item["bottles1"]).toFixed(
            2
          )}\n% of sales: ${parseFloat(item["sales_pcnt1"]).toFixed(2)}`;

          return [
            `${item.productname} (${item["sales_pcnt1"]}%)`,
            parseFloat(item[metric] || 0),
            tooltip
          ];
        })
      ];

      //sort by percent desc
      data = data.sort(function(a, b) {
        return b[1] - a[1];
      });

      this.charts.chart1.totals.totalSold = 0;
      this.charts.chart1.totals.totalSold = data
        .map(function(v) {
          return v[1];
        })
        .reduce((sum, x) => sum + x);

      let filter = this.$refs.filteringPanel.selected;
      this.charts.chart1.subtitle = `Period: ${moment(
        filter.period.startDate
      ).format("YYYY-MM-DD")} - ${moment(filter.period.endDate).format(
        "YYYY-MM-DD"
      )}`;

      this.charts.chart1.pagination.total = data.length;
      data = data.slice(
        this.charts.chart1.pagination.value * 10 - 10,
        this.charts.chart1.pagination.value * 10
      );

      //data = data.slice(0, 10)

      this.charts.chart1.totals.totalSoldSelection = 0;
      this.charts.chart1.totals.totalSoldSelection = data
        .map(function(v) {
          return v[1];
        })
        .reduce((sum, x) => sum + x);

      this.charts.chart1.chartData = headers.concat(data);
    },
    drawChart2(response) {
      this.charts.chart2.loading = false;

      if (response.length === 0) return;

      this.charts.chart2.rawData = response;

      this.updateChart2();
    },
    updateChart2() {
      let headers = [
        [
          "Product",
          "$ Sold",
          {
            type: "string",
            role: "tooltip"
          }
        ]
      ];

      let metric = undefined;

      metric = "sales2";

      let data = [
        ...this.charts.chart2.rawData.map(item => {
          const tooltip = `Product: ${
            item.productname
          }\nTotal sales $: ${parseFloat(item["sales2"]).toFixed(
            2
          )}\nBottles: ${parseFloat(item["bottles2"]).toFixed(
            2
          )}\n% of sales: ${parseFloat(item["sales_pcnt2"]).toFixed(2)}`;
          return [
            `${item.productname} (${item["sales_pcnt2"]}%)`,
            parseFloat(item[metric] || 0),
            tooltip
          ];
        })
      ];

      //sort by percent desc
      data = data.sort(function(a, b) {
        return b[1] - a[1];
      });

      let filter = this.$refs.filteringPanel1.selected;
      this.charts.chart2.subtitle = `Period: ${moment(
        filter.period.startDate
      ).format("YYYY-MM-DD")} - ${moment(filter.period.endDate).format(
        "YYYY-MM-DD"
      )}`;

      this.charts.chart2.totals.totalSold = 0;
      this.charts.chart2.totals.totalSold = data
        .map(function(v) {
          return v[1];
        })
        .reduce((sum, x) => sum + x);

      this.charts.chart2.pagination.total = data.length;
      data = data.slice(
        this.charts.chart2.pagination.value * 10 - 10,
        this.charts.chart2.pagination.value * 10
      );

      this.charts.chart2.totals.totalSoldSelection = 0;
      this.charts.chart2.totals.totalSoldSelection = data
        .map(function(v) {
          return v[1];
        })
        .reduce((sum, x) => sum + x);

      this.charts.chart2.chartData = headers.concat(data);
    },
    drawChart3: function(response) {
      this.charts.chart3.loading = false;

      if (response.length === 0) return;

      this.charts.chart3.rawData = response;

      this.updateChart3();
    },
    updateChart3() {
      let headers = [
        //['Product', '% change','$ Sold 1','$ Sold 2']
        ["Product", "% change"]
      ];

      let metric = "sales_delta_percent2";

      if (this.selectedMetric().id === "sold") metric = "sales_delta_percent2";
      if (this.selectedMetric().id === "bottles")
        metric = "bottles_delta_percent2";
      if (this.selectedMetric().id === "alc_amount")
        metric = "alc_amount_delta_percent2";

      let data = [
        ...this.charts.chart3.rawData
          .filter(item => parseFloat(item[metric] || 0) !== 0)
          .map(item => {
            return [
              item.productname,
              parseFloat(item[metric] || 0),
              parseFloat(item["sales1"] || 0),
              parseFloat(item["sales2"] || 0)
            ];
          })
      ];

      //sort by percent desc
      data = data.sort(function(a, b) {
        return b[2] - a[2];
      });

      let filter1 = this.$refs.filteringPanel.selected;
      let filter2 = this.$refs.filteringPanel2.selected;

      this.charts.chart3.subtitle = `Difference between periods [${moment(
        filter1.period.startDate
      ).format("YYYY-MM-DD")} - ${moment(filter1.period.endDate).format(
        "YYYY-MM-DD"
      )}] and [${moment(filter2.period.startDate).format(
        "YYYY-MM-DD"
      )} - ${moment(filter2.period.endDate).format("YYYY-MM-DD")}]`;

      this.charts.chart3.pagination.total = data.length;
      data = data.slice(
        this.charts.chart3.pagination.value * 10 - 10,
        this.charts.chart3.pagination.value * 10
      );

      data = [
        ...data.map(item => {
          return [item[0], item[1]];
        })
      ];

      this.charts.chart3.chartData = headers.concat(data);
    },
    drawChart4: function(response) {
      this.charts.chart4.loading = false;

      if (response.length === 0) return;

      this.charts.chart4.rawData = response;

      this.updateChart4();
    },
    updateChart4() {
      let headers = [["Product", "Invoices", "Accounts"]];

      let data = [
        ...this.charts.chart4.rawData.map(item => {
          return [
            item.productname,
            parseFloat(item.invoices_count1),
            parseFloat(item.accounts_count1)
          ];
        })
      ];

      //sort by count desc
      data = data.sort(function(a, b) {
        return b[1] - a[1];
      });

      let filter = this.$refs.filteringPanel.selected;
      this.charts.chart4.subtitle = `Period: ${moment(
        filter.period.startDate
      ).format("YYYY-MM-DD")} - ${moment(filter.period.endDate).format(
        "YYYY-MM-DD"
      )}`;

      this.charts.chart4.pagination.total = data.length;

      data = data.slice(
        this.charts.chart4.pagination.value * 10 - 10,
        this.charts.chart4.pagination.value * 10
      );

      data = [
        ...data.map(item => {
          return [item[0], item[1], item[2]];
        })
      ];

      this.charts.chart4.chartData = headers.concat(data);
    },
    drawChart5: function(response) {
      this.charts.chart5.loading = false;

      if (response.length === 0) return;

      this.charts.chart5.rawData = response;

      this.updateChart5();
    },
    updateChart5() {
      let headers = [["Product", "Invoices", "Accounts"]];

      let data = [
        ...this.charts.chart5.rawData.map(item => {
          return [
            item.productname,
            parseFloat(item.invoices_count2),
            parseFloat(item.accounts_count2)
          ];
        })
      ];

      //sort by count desc
      data = data.sort(function(a, b) {
        return b[1] - a[1];
      });

      let filter = this.$refs.filteringPanel3.selected;

      this.charts.chart5.subtitle = `Period: ${moment(
        filter.period.startDate
      ).format("YYYY-MM-DD")} - ${moment(filter.period.endDate).format(
        "YYYY-MM-DD"
      )}`;

      this.charts.chart5.pagination.total = data.length;
      data = data.slice(
        this.charts.chart5.pagination.value * 10 - 10,
        this.charts.chart5.pagination.value * 10
      );

      data = [
        ...data.map(item => {
          return [item[0], item[1], item[2]];
        })
      ];

      this.charts.chart5.chartData = headers.concat(data);
    },
    onChart1TableMount() {
      this.chart1Table.dataSet = this.charts.chart1.rawData.map(function(i) {
        let obj = {};

        obj["Product"] = i.productname;
        obj["$ Sold"] = i["sales1"];
        obj["Bottles"] = parseFloat(i["bottles1"]);
        obj["Amount of alcohol"] = parseFloat(i["alc_amount1"] || 0);

        return obj;
      });
    },
    onChart2TableMount() {
      this.chart2Table.dataSet = this.charts.chart2.rawData.map(function(i) {
        let obj = {};

        obj["Product"] = i.productname;
        obj["$ Sold"] = i["sales2"];
        obj["Bottles"] = parseFloat(i["bottles2"]);
        obj["Amount of alcohol"] = parseFloat(i["alc_amount2"] || 0);

        return obj;
      });
    },
    onChart3TableMount() {
      let title = "";
      let column = "";

      if (this.selectedMetric().id === "sold") {
        title = "$ Sold";
        column = "sales";
        this.chart3Table = tables.chart3Table;
      }

      if (this.selectedMetric().id === "bottles") {
        title = "Bottles";
        column = "bottles";
        this.chart3Table = tables.chart3TableBottles;
      }

      if (this.selectedMetric().id === "alc_amount") {
        title = "Amount";
        column = "alc_amount";
        this.chart3Table = tables.chart3TableAlcAmount;
      }

      this.chart3Table.dataSet = this.charts.chart3.rawData.map(function(i) {
        let obj = {};

        obj["Product"] = i.productname;
        obj["Rank 1"] = parseFloat(i.rank1);
        obj["Rank 2"] = parseFloat(i.rank2);
        obj[`${title} 1`] = i[`${column}1`];
        obj[`${title} 2`] = i[`${column}2`];
        obj["Difference"] = parseFloat(i[`${column}_delta2`]);
        obj["% Change"] = parseFloat(i[`${column}_delta_percent2`] || 0);

        return obj;
      });
    },
    onChart4TableMount() {
      this.chart4Table.dataSet = this.charts.chart4.rawData.map(function(i) {
        let obj = {};

        obj["Product"] = i.productname;
        obj["Invoices"] = parseFloat(i["invoices_count1"]);
        obj["Accounts"] = parseFloat(i["accounts_count1"]);

        return obj;
      });
    },
    drilldownChart41: function(e) {
      let topFilter = this.$refs.filteringPanel.selected;
      let filter3 = this.$refs.filteringPanel3.selected;

      let filterRequest = Object.assign({}, topFilter);

      //get selected chart elements
      let selectedItem = e.data[e.selection.row + 1][0];

      filterRequest.selectedItem = selectedItem;

      filterRequest.category = e.data[0][e.selection.column];

      let description = `${
        this.$refs.chart4.title
      } / [Selected item: ${selectedItem}, Period: ${moment(
        filterRequest.period.startDate
      ).format("DD MMM, YY")} - ${moment(filterRequest.period.endDate).format(
        "DD MMM, YY"
      )}];`;

      //overwrite top level filter if exists
      !filter3.territory ||
        (filterRequest.territory = Object.assign({}, filter3.territory));
      !filter3.country ||
        (filterRequest.country = Object.assign({}, filter3.country));
      !filter3.state ||
        (filterRequest.state = Object.assign({}, filter3.state));
      !filter3.chain ||
        (filterRequest.chain = Object.assign({}, filter3.chain));

      this.$store.dispatch("drilldown.save", {
        filter: filterRequest,
        parent: this.$route.name,
        description: description,
        method: "Product.WH-Overview.InvoicesAccountsChart4Drilldown"
      });

      //open new page
      this.$router.push({ name: "Chart drilldown" });
    },
    drilldownChart42: function(e) {
      let topFilter = this.$refs.filteringPanel.selected;
      let filter3 = this.$refs.filteringPanel3.selected;

      let filterRequest = Object.assign({}, topFilter);

      //get selected chart elements
      let selectedItem = e.data[e.selection.row + 1][0];

      filterRequest.selectedItem = selectedItem;

      filterRequest.category = e.data[0][e.selection.column];

      filterRequest.period = Object.assign({}, filter3.period);

      let description = `${
        this.$refs.chart4.title
      } / [Selected item: ${selectedItem}, Period: ${moment(
        filterRequest.period.startDate
      ).format("DD MMM, YY")} - ${moment(filterRequest.period.endDate).format(
        "DD MMM, YY"
      )}];`;

      //overwrite top level filter if exists
      !filter3.territory ||
        (filterRequest.territory = Object.assign({}, filter3.territory));
      !filter3.country ||
        (filterRequest.country = Object.assign({}, filter3.country));
      !filter3.state ||
        (filterRequest.state = Object.assign({}, filter3.state));
      !filter3.chain ||
        (filterRequest.chain = Object.assign({}, filter3.chain));

      this.$store.dispatch("drilldown.save", {
        filter: filterRequest,
        parent: this.$route.name,
        description: description,
        method: "Product.WH-Overview.InvoicesAccountsChart4Drilldown"
      });

      //open new page
      this.$router.push({ name: "Chart drilldown" });
    },
    onChart5TableMount() {
      this.chart5Table.dataSet = this.charts.chart5.rawData.map(function(i) {
        let obj = {};

        obj["Product"] = i.productname;
        obj["Invoices"] = parseFloat(i["invoices_count2"]);
        obj["Accounts"] = parseFloat(i["accounts_count2"]);

        return obj;
      });
    }
  }
};
</script>

<style></style>
